// Process escaped chars and hardbreaks

'use strict';

var isSpace = require('../common/utils').isSpace;
var ESCAPED = [];
for (var i = 0; i < 256; i++) {
  ESCAPED.push(0);
}
'\\!"#$%&\'()*+,./:;<=>?@[]^_`{|}~-'.split('').forEach(function (ch) {
  ESCAPED[ch.charCodeAt(0)] = 1;
});
module.exports = function escape(state, silent) {
  var ch,
    pos = state.pos,
    max = state.posMax;
  if (state.src.charCodeAt(pos) !== 0x5C /* \ */) {
    return false;
  }
  pos++;
  if (pos < max) {
    ch = state.src.charCodeAt(pos);
    if (ch < 256 && ESCAPED[ch] !== 0) {
      if (!silent) {
        state.pending += state.src[pos];
      }
      state.pos += 2;
      return true;
    }
    if (ch === 0x0A) {
      if (!silent) {
        state.push('hardbreak', 'br', 0);
      }
      pos++;
      // skip leading whitespaces from next line
      while (pos < max) {
        ch = state.src.charCodeAt(pos);
        if (!isSpace(ch)) {
          break;
        }
        pos++;
      }
      state.pos = pos;
      return true;
    }
  }
  if (!silent) {
    state.pending += '\\';
  }
  state.pos++;
  return true;
};